const FooterView:React.FC=()=>
{

    return(<footer id="footer">
    <div className="container">
        <div className="row py-5">
            <div className="col-md-3">
                <h4 className="mb-2 text-uppercase">about us </h4>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Our Team
                    </a>
                </p>
                <p className="mb-3"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Join Us
                    </a>
                </p>
            </div>

            <div className="col-md-3">
                <h4 className="mb-2 text-uppercase">Magazine</h4>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Advertised
                    </a>
                </p>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Subscribe
                    </a>
                </p>
                <p className="mb-3"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Archives
                    </a>
                </p>
            </div>


            <div className="col-md-3">
                <h4 className="mb-2 text-uppercase">Education Today Values</h4>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Caring Environment
                    </a>
                </p>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Privacy Policy
                    </a>
                </p>
                <p className="mb-3"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        Terms of Service
                    </a>
                </p>
            </div>

            <div className="col-md-3">
                <h4 className="mb-2 text-uppercase">contact us</h4>
                <p className="mb-0"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        For Product &amp; Service Support
                    </a>
                </p>
                <p className="mb-3"><a href="#" className="btn-flat btn-xs text-color-light p-relative top-5">
                        For Any Other Query
                    </a>
                </p>
            </div>


        </div>
    </div>
</footer>)
}
export default FooterView;