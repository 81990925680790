// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home/HomeView';
import SchoolRegistration from './pages/SchoolRegistration/SchoolRegistrationView';

import EduNewsView from './components/EduNews/EduNewsView';
import RegistrationSuccess from './pages/RegistrationSuccess/RegistrationSuccessView';
import EduNews from './pages/EduNewsList/EduNewsListView';
import Header from './components/Header/HeaderView';
import SchoolsList from './pages/SchoolsList/SchoolListView';
import SchoolDetails from './pages/SchoolDetails/SchoolDetailsView';
import StateListView from './pages/StatesList/StateListView';
import DistrictsListView from './pages/DistrictsList/DistrictsListView';
import CitiesListView from './pages/CitiesList/CitiesListView';
import ClustersListView from './pages/ClustersList/ClustersListView';
import AdmissionHelpHomeView from './pages/AdmissionHelpHome/AdmissionHelpHomeView';
 


const App: React.FC = () => {
  return (
    
     <Router>
    <Routes>
      <Route path="/home" element={<Home/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/schoolregistration" element={<SchoolRegistration/>} />
      <Route path="/schoolfeeds" element={<EduNewsView/>} />
      <Route path="/registrationsuccess" element={<RegistrationSuccess/>} />
      <Route path="/edunews" element={<EduNews/>} />

      <Route path="/head" element={<Header/>} />
      <Route path="/schools" element={<SchoolsList/>} />
      <Route path="/viewschool" element={<SchoolDetails/>} />
      <Route path="/:school_name" element={<SchoolDetails/>} />
      
      <Route path="/statelist" element={<StateListView/>} />
      <Route path="/districtlist" element={<DistrictsListView/>} />
      <Route path="/citieslist" element={<CitiesListView/>} />

      <Route path="/clusterslist" element={<ClustersListView/>} />
       
      <Route path="/admissionhelphome" element={<AdmissionHelpHomeView/>} />
       

      
      {/* <Route path="/state" element={<States onChildDataChange={function (data: string): void {
          throw new Error('Function not implemented.');
        } }/>} /> */}
      
    </Routes>
    </Router>
  );
};

export default App;
