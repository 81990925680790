const HomeMagazineHelp:React.FC=()=>
{


    return (

        <section className="bg-yellow p-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <h1 className="mb-5 fw-600">Education Today <br/>Magazine</h1>

                        <p className="text-dark">Choose from over 200 titles of education Today Magazine. Each issue is 24
                            pages in length and packed with iconic photographs, original illustrations, time lines, and
                            digestible facts.</p>


                        <button className="btn btn-danger">View</button>
                    </div>

                    <div className="col-md-6">
                        <img src="img/images/homepagemagazines.png" alt="" className="w-100"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeMagazineHelp;