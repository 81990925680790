import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useSelector } from "react-redux";
import { AppState } from "../../store/Page.Actions";
import { useNavigate } from "react-router";
import React from "react";

export function SchoolDetailsViewModel(school_name:any)
{
  debugger;
  const navigate = useNavigate();
  const repository =SchoolRepository();
  const imagePath=repository.imagePath;
  const divRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {};

  const scrollToDiv = (refName: string) => {
    debugger;
    const element = divRefs[refName]?.current;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const initializeRef = (refName: string) => {
    divRefs[refName] = React.createRef();
  };
  const [school,setSelectedSchool]=useState({school_id:0,school_name:'',
    state_name:'',
    district_name:'',
    city_name:'',
    address:'',
    contact_no:'',
    location:'',
    board_for_class_10th:'',
    school_type:'',
    image:'',
    year_of_establishment:'',
    classes:'',
    instruction_medium:'',
    computer_aided_learning:'No',
    library:'No',
    playground:'No',
    management:'No',
    about:''
  ,total_teachers:''
  ,male_teachers:''
  ,female_teachers:''
  ,head_teacher:''
  ,contract_teachers:''
  ,pre_primary_teachers:''
  ,primary_teachers:''
  ,middle_school_teachers:''
  ,high_school_teachers:''
  ,class_rooms:''
  ,computers:''
  ,books_in_library:''
  ,boys_toilet:''
  ,girls_toilet:''
  ,is_school_residential:''
  ,residential_type:''
  ,electricity:''
  ,drinking_water:''
  ,mid_day_meal:''
  ,wall:''
  ,ramps_for_disable:''
  ,canteen:''
  ,is_verified:''
  ,transport_facility:''
  ,twitter_url:''
  ,facebook_url:''
  ,whatsapp_url:''
  ,youtube_url:''
  ,instagram_url:''
  });
  const [announcemnts,setAnnouncemnts]=useState([]);
  const [schoolBanners,setSchoolBanners]=useState([]);
  const [schoolgalleries,setSchoolgalleries]=useState([]);
  const selectedSchool = useSelector((state: AppState) =>
  {
  //    / debugger; 
    return  state.storeData.viewName=='SelectdSchool'?state.storeData.storeData:null;
  }
  );
 

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const imageURLs = ['https://thehill.com/wp-content/uploads/sites/2/2019/11/istock-children-classroom-diverse-2019.jpg?strip=1',
    // 'https://www.collinsdictionary.com/images/full/school_309241295.jpg', 'https://thehill.com/wp-content/uploads/sites/2/2019/11/istock-children-classroom-diverse-2019.jpg?strip=1'];
    
    const caption = 'Example Image';
   const openLightbox = (index: number) => {
     setCurrentIndex(index);
     setLightboxOpen(true);
   };
 
   const closeLightbox = () => {
     setLightboxOpen(false);
   };
 
   const goToPrev = () => {
     setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
   };
 
   const goToNext = () => {
     setCurrentIndex((prevIndex) => Math.min(schoolgalleries.length - 1, prevIndex + 1));
   };
 

useEffect(()=>
{
  

  if(typeof(school_name) !='undefined' &&  school_name!='')
  {
    debugger;
    getSchoolDetailsFromSchoolName();
  }
 else{
  setSelectedSchool(selectedSchool);
  getSchoolResource(selectedSchool.school_id);
  getAnnouncements(selectedSchool.school_id);
  getContents(selectedSchool.school_id);
}
},[])



// useEffect(()=>
// {
//   try{
//   lstContent.forEach((item: any) => {
//     initializeRef(item.title);
//   });
// }catch(e)
// {
  
// }

// });


const [lstContent,setContent]=useState([]);
const getContents = async (school_id:any) => {
  setContent([]);
  debugger;
  try {
      const data: any = await repository.getContents({ 'school_id': school_id });
    //  / divRefs['Gallery'] = React.createRef();
    

      setContent(
          data

      )
      data.forEach((item: any) => {
        initializeRef(item.title);
      });
     

  }
  catch (error) {
    setContent(
           []
      )
  }
}



const getSchoolResource =async (school_id:any)=>
{
  debugger;
  
  const data:any =await repository.getSchoolResource( school_id );
if(data.length>0)
{
  debugger;

let banners:any=[];
let gallery:any=[];

  for(let i=0;i<data.length;i++)
  {
    if(data[i].resource_type=='Banners')
    {
      banners.push({image:(imagePath+data[i].file_type+'.webp'),name:data[i].resource_name})
    }else{
      gallery.push((imagePath+data[i].file_type+'.webp'))
    }

  }
  setSchoolBanners(banners);
  setSchoolgalleries(gallery);

}
//setSchoolResources(data);

  
}

const getSchoolDetailsFromSchoolName=async ()=>
{
  debugger;
  const data:any =await repository.searchSchools( {'school_name':school_name} );
  if(data.length>0)
  {
    debugger;
    setSelectedSchool(data[0]);
    getSchoolResource(data[0].school_id);
    getAnnouncements(data[0].school_id);
    getContents(data[0].school_id);
  }else{
    navigate('/');
  }
  
}

const getAnnouncements=async (school_id:any)=>
{


    const data:any = await repository.getAnnouncements({school_id:school_id});

    let lstAnnouncementItems:any=[];
    debugger;
if(data.length>0)
{

  for(let i=0;i<data.length;i++)
  {
    lstAnnouncementItems.push(data[i].information);
  }
}

    setAnnouncemnts(lstAnnouncementItems);
}

   

   return {initializeRef,divRefs,scrollToDiv,lstContent,announcemnts,lightboxOpen,setLightboxOpen,currentIndex,setCurrentIndex,openLightbox,closeLightbox,goToPrev,goToNext,caption,school,schoolBanners,schoolgalleries,imagePath};
} 
 
 