import { useEffect, useState } from "react";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function SchoolListViewModel()
{

    const  repository=SchoolRepository();
    const imagePath=repository.imagePath;
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const[lstSchools,setSchools]=useState<any[]>([]);


    const[lstfilteredSchools,setfilteredSchools]=useState<any[]>([]);
    const[lstSelectedClassification,setSelectedClassification]=useState<any[]>([]);
    const[lstSelectedBoard,setSelectedBoard]=useState<any[]>([]);
    const[lstSelectedClass,setSelectedClass]=useState<any[]>([]);
    const[lstSelectedManagement,setSelectedManagement]=useState<any[]>([]); 
    const[lstSelectedFacilities,setSelectedFacilities]=useState<any[]>([]);

    
    const filterSchools=()=>
    {
       // let  (lstSchools;
debugger;
setfilteredSchools([]);
      //  ENUM('Co-educational', 'Girls Only', 'Boys Only')
      let lstfltDet:any=[];
if(lstSelectedClassification.length>0 ||  lstSelectedBoard.length>0 || lstSelectedClass.length>0 || lstSelectedManagement.length>0 || lstSelectedFacilities.length>0)
{


if(lstSelectedClassification.length>0)
{
 

(lstSchools.filter(x=>x.school_type==lstSelectedClassification[0])).map(item=>
    {

        lstfltDet.push(item);
    });




}
else
if(lstSelectedBoard.length>0)
{

(lstSchools.filter(x=>x.board_for_class_10th==lstSelectedBoard[0])).map(item=>
    {

        lstfltDet.push(item);
    });;

}
else
if(lstSelectedClass.length>0)
{

(lstSchools.filter(x=>x.classes==lstSelectedClass[0])).map(item=>
    {

        lstfltDet.push(item);
    });;

}
else
if(lstSelectedManagement.length>0)
{

(lstSchools.filter(x=>x.management==lstSelectedManagement[0])).map(item=>
    {

        lstfltDet.push(item);
    });;

}
else
if (lstSelectedFacilities.length>0)
{

switch(lstSelectedFacilities[0].facility)
{
case 'Computer Aided Learning':
    {

        (lstSchools.filter(x=>x.computer_aided_learning=='Yes')).map(item=>
            {
        
                lstfltDet.push(item);
            });;
        
        break;
    }
    case 'Library':
    {

        (lstSchools.filter(x=>x.library=='Yes')).map(item=>
            {
        
                lstfltDet.push(item);
            });;
        break;
    }
    case 'Playground':
        {
    
            (lstSchools.filter(x=>x.playground=='Yes')).map(item=>
                {
            
                    lstfltDet.push(item);
                });;
            break;
        }
        case 'Transport Facility':
            {
                (lstSchools.filter(x=>x.transport_facility=='Available')).map(item=>
                    {
                
                        lstfltDet.push(item);
                    });;
                break;
                
            }



}



}
//if(lstfltDet.length>0)
{

for(let i=0;i<lstSelectedClassification.length;i++)

{
     
    (lstSchools.filter(x=> x.school_type==lstSelectedClassification[i])).map(item=>
        {
    
            lstfltDet.push(item);
        });;;


}
}

//if(lstfltDet.length>0)
{

for(let i=0;i<lstSelectedBoard.length;i++)

{
     
    (lstSchools.filter(x=> x.board_for_class_10th==lstSelectedBoard[i])).map(item=>
        {
    
            lstfltDet.push(item);
        });;;


}
}
//if(lstfltDet.length>0)
{


for(let i=0;i<lstSelectedClass.length;i++)

{
     
    (lstSchools.filter(x=> x.classes==lstSelectedClass[i])).map(item=>
        {
    
            lstfltDet.push(item);
        });;;


}
}
//if(lstfltDet.length>0)
{

for(let i=0;i<lstSelectedManagement.length;i++)

{
     
    (lstSchools.filter(x=> x.management==lstSelectedManagement[i])).map(item=>
        {
    
            lstfltDet.push(item);
        });;;


}
}


// if(lstfltDet.length>0)
// {
    for(let i=0;i<lstSelectedFacilities.length;i++)

    {

    switch(lstSelectedFacilities[i].facility)
{
case 'Computer Aided Learning':
    {

        (lstSchools.filter(x=>x.computer_aided_learning=='Yes')).map(item=>
            {
        
                lstfltDet.push(item);
            });;;
        
        break;
    }
    case 'Library':
    {

        (lstSchools.filter(x=>x.library=='Yes')).map(item=>
            {
        
                lstfltDet.push(item);
            });;;
        break;
    }
    case 'Playground':
        {
    
            (lstSchools.filter(x=>x.playground=='Yes')).map(item=>
                {
            
                    lstfltDet.push(item);
                });;;
            break;
        }
        case 'Transport Facility':
            {
                (lstSchools.filter(x=>x.transport_facility=='Available')).map(item=>
                    {
                
                        lstfltDet.push(item);
                    });;;
                break;
                
            }


        }
//}

} 
const uniqueRows:any = [];
lstfltDet.forEach((row:any) => {
    // Check if the row with the same id already exists in the result array
    if (uniqueRows.findIndex((r:any) => r.school_id === row.school_id) === -1) {
      uniqueRows.push(row);
    }
  });
 
setfilteredSchools( uniqueRows);
}else{
    setfilteredSchools( lstSchools);

}

   
    }


    const facilitiesSelectionValueChange=(checked:any,name:string)=>
    {
        debugger;
        if(checked)
        {
            lstSelectedFacilities.push({facility:name});
            
        }else{
            let index=lstSelectedFacilities.findIndex(x=>x==name);
            lstSelectedFacilities .splice(index,1);
        }
        setSelectedFacilities(lstSelectedFacilities);
        filterSchools();
    }



    const managementSelectionValueChange=(checked:any,name:string)=>
    {
        debugger;
        if(checked)
        {
            lstSelectedManagement.push(name);
            
        }else{
            let index=lstSelectedManagement.findIndex(x=>x==name);
            lstSelectedManagement .splice(index,1);
        }
        setSelectedManagement(lstSelectedManagement);
        filterSchools();
    }




    const classSelectionValueChange=(checked:any,name:string)=>
    {
        debugger;
        if(checked)
        {
            lstSelectedClass.push(name);
            
        }else{
            let index=lstSelectedClass.findIndex(x=>x==name);
            lstSelectedClass .splice(index,1);
        }
        setSelectedClass(lstSelectedClass);
        filterSchools();
    }





    const boardSelectionValueChange=(checked:any,name:string)=>
    {
        debugger;
        if(checked)
        {
            lstSelectedBoard.push(name);
            
        }else{
            let index=lstSelectedBoard.findIndex(x=>x==name);
            lstSelectedBoard .splice(index,1);
        }
        setSelectedBoard(lstSelectedBoard);
        filterSchools();
    }

    const classificationSelectionValueChange=(checked:any,name:string)=>
    {
        debugger;
        if(checked)
        {
            lstSelectedClassification.push(name);
            
        }else{
            let index=lstSelectedClassification.findIndex(x=>x==name);
            lstSelectedClassification .splice(index,1);
        }
        setSelectedClassification(lstSelectedClassification);
        filterSchools();
    }

 
    const searchData = useSelector((state: AppState) =>
    {
    //    / debugger; 
      return  state.storeData.viewName=='SearchSchool'?state.storeData.storeData:null;
    }
    );

useEffect(()=>{

const fetchSchoolList= async ()=>
{

 
      const data = await repository.searchSchools(searchData);
      debugger;
      setSchools(data);
      setfilteredSchools(data);
    //  / debugger;
}
fetchSchoolList();
}, [searchData]);

   
  const btnViewSchoolClick=(school:any )=> {

    dispatch({
			type: ActionType.STORE,
			payload: {
				storeData:school,
				viewName:'SelectdSchool',
			},
		  });
    navigate('/viewschool');
  }

  return{classificationSelectionValueChange,boardSelectionValueChange,classSelectionValueChange,managementSelectionValueChange,facilitiesSelectionValueChange,
    lstfilteredSchools
    ,imagePath
    ,btnViewSchoolClick

}
}