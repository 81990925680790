const MenuView:React.FC=()=>
{
    return (<nav className="navbar navbar-expand-lg navbar-custom p-0">

    <div className="container">

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

            <span className="navbar-toggler-icon"></span>

        </button>

        <div className="collapse navbar-collapse justify-content-center" id="navbarNav">

            <ul className="navbar-nav ml-auto">

            <li className="nav-item nav-item-br-start menu-custom-button"  >
            
<a className="nav-link px-0" href="/"><i className="fa fa-home"></i></a>

</li>



                <li className="nav-item dropdown"  >

                    <a className="nav-link px-0" href="/edunews">Edu-News</a>

                </li>


                {/* <li className="nav-item dropdown">

<a className="nav-link px-0" href="/statelist">School List</a>



</li> */}


                <li className="nav-item dropdown">

                    <a className="nav-link px-0" href="/admissionhelphome">Admission Help</a>

                   

                </li>



                <li className="nav-item">

                    <a className="nav-link px-0" href="#">School Stores</a>

                </li>

                <li className="nav-item">

                    <a className="nav-link px-0" href="#">Resources</a>

                </li>

                
                <li className="nav-item  nav-item-br-end menu-custom-button"  >
            
            <a className="nav-link px-0" href="/"><i className="fa fa-shopping-cart"></i></a>
            
            </li>
            


            </ul>

        </div>

    </div>

</nav>);

}
export default MenuView;