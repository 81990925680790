import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ActionType } from "../../store/Page.Actions";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function HeaderViewModel()
{

	const repository=SchoolRepository()
    const dispatch = useDispatch();
	const navigate = useNavigate();
	const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
	const [city_id, setSelectedCity] = useState<number>(0);
	const [selectedCity, setSelectedCityValue] = useState<{ label: string; value: string } | null>(null);
	const [searchedInstitution, setsearchedInstitution] = useState<string>('');
	const [searchingDetails, setSearchingDetails] = useState<{city_id:number,search_string:string}| null>(null);

	const loadOptions = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
		debugger;
	   
		const filteredOptions = filterOptions(inputValue);
		callback(filteredOptions);
	  };
	const  btnSearchClick=()=>
	  {
		debugger;
let sDetails:any={city_id:city_id,search_string:searchedInstitution};
		setSearchingDetails(sDetails);
	 
		dispatch({
			type: ActionType.STORE,
			payload: {
				storeData:sDetails,
				viewName:'SearchSchool',
			},
		  });

		navigate('/schools');
	  }

	  const onSearchedInstitutionChange=(inputValue: string)=>
	  {
		setSelectedCity(0);
		setSelectedCityValue(null);
		setsearchedInstitution(inputValue);
	  }

	  const filterOptions = (inputValue: string) => {
		debugger;
	
		if(inputValue!='')
		{
		  return  cities.filter((option) =>
		{
		  return option.label.toLowerCase().includes(inputValue.toLowerCase());
		  
		}
		 
		);
	  }else{
	
		return cities;
	
	  }
	  };
	
	  const handleCityChange = (selectedOption: { value: string; label: string } | null) => {
		debugger;
		setSelectedCityValue(selectedOption);
		//setSelectedOption(selectedOption);
		setSelectedCity(selectedOption?(+selectedOption.value):0);
		setsearchedInstitution('');
	  };
	

	  
useEffect(()=>
{

const loadCities=async ()=>
{ 
	const data = await repository.loadCities();

	  const options: { label: string; value: string }[] = data.map((item: any) => ({
		label: item.city_name,
		value: item.city_id.toString(),
	  }));

	  setCities(options);

}
loadCities();

	
},[])


    return {onSearchedInstitutionChange,cities,btnSearchClick,selectedCity,handleCityChange,loadOptions,searchedInstitution}
}