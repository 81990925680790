
import Marquee from "react-fast-marquee";
import Footer from "../../components/Footer/FooterView";
import Header from "../../components/Header/HeaderView";
import Lightbox from "../../components/Lightbox/LightboxView";
import { SchoolDetailsViewModel } from "./SchoolDetailsViewModel";
import React from "react";
import Menu from "../../components/Menu/MenuView";
import { useParams } from "react-router-dom";
import { isForInStatement } from "typescript";
const SchoolDetails: React.FC = () => {
    const { school_name } = useParams<{ school_name: string }>();

    const viewModel = SchoolDetailsViewModel(school_name);
debugger;
    return (<>
      {(school_name!='' && typeof(school_name)!='undefined')?<Menu/>:<Header />}  

        <section className="bg-gray pt-1">
            {viewModel.announcemnts.length > 0 ?
                <div className="container  overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-1">
                            <div >
                                <Marquee>
                                    <strong style={{ color: '#dc3545' }}>{viewModel.school.school_name}:</strong>
                                    {viewModel.announcemnts.map((item, index) => (





                                        <div key={index} style={{ margin: '0 10px', color: 'black' }}>
                                            {item}
                                        </div>
                                    ))}

                                </Marquee>
                            </div>

                            {/* <div className="marquee-bg ">
        <p className="marquee-text mb-1"> <strong className="schl-title">{viewModel.school.school_name} :</strong>
       
       {viewModel.announcemnts.map((anncmt:any,i)=>
       
       <b className="text-white fw-500 fs-17">{anncmt.information} </b>
       
       )}
         </p>
      </div> */}
                        </div>
                    </div>
                </div>

                : ''}
            <div className="container">


                <div className="row">

                    <div className="col-md-12">


                        <div id="carouselExampleCaptions" className="carousel slide p-0  mt-0 mb-0" data-bs-ride="carousel">
                            {/* <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div> */}
                            <div className="carousel-inner brder-rds">
                                {
                                    viewModel.schoolBanners.map((item: any, i) =>
                                    (
                                        <div className={i == 0 ? 'carousel-item active' : 'carousel-item'} >
                                            <img src={item.image} className="carousel-img d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <p className="p-title">{item.name}</p>
                                            </div>
                                        </div>
                                    )
                                    )
                                }


                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>


                    </div>
                    <div className="col-md-12 mb-0 mt-3">
                        <div className="row mb-2">
                            <div className="col-md-2 col-3">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-start mt-7rem" style={{ marginTop: (viewModel.schoolBanners.length > 0 ? '-7rem' : '-1rem') }}>
                                    <img src={`${viewModel.imagePath}${viewModel.school.image}.webp?${new Date().getTime()}`} alt="Profile" className="rounded-circle pfl-img" />
                                </div>
                            </div>
                            <div className="col-md-8 col-12">
                                {/* <div className="school-name ms-4 mt-2rem d-flex">
                                    <h4 className="f-29 header-title-color">{viewModel.school.school_name} | {viewModel.school.state_name},  {viewModel.school.city_name}</h4>
                                </div>
                                <div className="col-md-12 ms-4"><h4><span className="text-warning">N/A</span><i className="fa fa-star text-warning" aria-hidden="true"></i><i className="fa fa-star text-warning" aria-hidden="true"></i><i className="fa fa-star-o text-warning" aria-hidden="true"></i><i className="fa fa-star-o text-warning" aria-hidden="true"></i><i className="fa fa-star-o text-warning" aria-hidden="true"></i><span className="fw-100 f-15">(0 vote)</span></h4>
                                    <div className="call-button mx-2">
                                        <a href={"tel:+" + viewModel.school.contact_no} className="call btn me-2">
                                            Call
                                        </a>
                                        {
                                            viewModel.school.location != '' ? (
                                                <a className="locate-btn btn" data-id="11078"
                                                href={"https://www.google.com/maps/place/" + viewModel.school.location.replace(',', '+') + "/@" + viewModel.school.location + ",18z"}
                                                target="_blank">
                                                <span><i className="fa fa-map-marker me-2" aria-hidden="true"></i>Show on map</span>
                                            </a>) : ''
                                        }
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className=" col-12 col-md-9">
                                            <h4 className="f-29 fw-400 text-dark text-uppercase">{viewModel.school.school_name} | {viewModel.school.state_name},  {viewModel.school.city_name}</h4>
                                    </div>
                                    <div className=" col-12 col-md-3">
                                        <ul className="social-media">
                                         
                                         
                                         {viewModel.school.twitter_url!=''?( <li>< a href={viewModel.school.twitter_url} target="_blank"><img src="/img/twitter logo.png"/></a></li>):''}
                                            {/* <li>< a href={viewModel.school.twitter_url} target="_blank"><img src="/img/twitter logo.png"/></a></li> */}
                                          
                                         {viewModel.school.facebook_url!=''?( <li>< a href={viewModel.school.facebook_url} target="_blank"><img src="/img/facebook logo.png"/></a></li>):''}
                                          
                                          
                                            {/* <li>< a href={viewModel.school.facebook_url} target="_blank"><img src="/img/facebook logo.png"/></a></li> */}
                                         
                                         
                                            {viewModel.school.whatsapp_url!=''?( <li><  a href={'https://wa.me/'+viewModel.school.whatsapp_url}   target="_blank"><img src="/img/whatsapp logo.png"/></a></li>):''}
                                         
                                            {/* <li>< a href={'https://wa.me/'+viewModel.school.whatsapp_url} target="_blank"><img src="/img/whatsapp logo.png"/></a></li> */}
                                          
                                            {viewModel.school.youtube_url!=''?( <li><  a href={viewModel.school.youtube_url}  target="_blank"><img src="/img/youtube logo.png"/></a></li>):''}
                                          
                                          
                                            {/* <li>< a href={viewModel.school.youtube_url} target="_blank"><img src="/img/youtube logo.png"/></a></li> */}


                                            {viewModel.school.instagram_url!=''?( <li><  a href={viewModel.school.instagram_url}  target="_blank"><img src="/img/instagram logo.png"/></a></li>):''}

                                            {/* <li>< a href={viewModel.school.instagram_url} target="_blank"><img src="/img/instagram logo.png"/></a></li> */}
                                        </ul>
                                    </div>

                                    <div className="col-md-4">
                                        <ul className="li-none">
                                            <li><strong className="text-dark">Address : </strong>{viewModel.school.address == '' ? '-' : viewModel.school.address}</li>
                                            <li><strong className="text-dark">City : </strong> {viewModel.school.city_name == '' ? '-' : viewModel.school.city_name}, {viewModel.school.state_name == '' ? '-' : viewModel.school.school_name}</li>
                                            <li><strong className="text-dark">Phone :</strong> {viewModel.school.contact_no == '' ? '-' : viewModel.school.contact_no}</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="li-none">
                                            <li><strong className="text-dark">Board : </strong> {viewModel.school.board_for_class_10th == '' ? '-' : viewModel.school.board_for_class_10th}</li>
                                            <li><strong className="text-dark">Medium : </strong>  {viewModel.school.instruction_medium == '' ? '-' : viewModel.school.instruction_medium}</li>
                                            <li><strong className="text-dark">Gender :</strong>{viewModel.school.school_type == '' ? '-' : viewModel.school.school_type}</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="li-none">
                                            <li><strong className="text-dark">Classes : </strong>{viewModel.school.classes == '' ? '-' : viewModel.school.classes}</li>
                                            <li><strong className="text-dark">Management : </strong> {viewModel.school.management==''?'-':viewModel.school.management}</li>
                                            <li><strong className="text-dark">Established : </strong> {viewModel.school.year_of_establishment == '' ? '-' : viewModel.school.year_of_establishment}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
  {viewModel.school.is_verified=='Verified'?

                            
                            (<div className="card-icon rounded-circle d-flex align-items-center justify-content-start mt-7rem" style={{ marginTop: (viewModel.schoolBanners.length > 0 ? '-7rem' : '-1rem') }}>
                                    <img src='/img/et website module 4.png'
                                     alt="verified"  style={{zIndex: '999'}}/>
                                </div>):''
}
                                </div>
                        </div>

                    </div>

                    

                    <section className="mt-4 mb-5">
                        <div className="row mx-auto resource-bg">
                        <div className="down w-150" onClick={() => viewModel.scrollToDiv('About School')} >
                            <div className="dwn-img">
                                <img src="/img/about school.png" alt="" />
                            </div>
                            <div className="vertical"></div>
                            <div className="round-point "></div>
                            <div className="text-below">About School</div>
                        </div>
                        
                        <div className="w-150 up" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Academic Programs')}>
                            <div className="text-above">Academic Programs</div>
                            <div className="round-point"></div>
                            <div className="vertical"></div>
                                <div className="dwn-img mt-3" style={{'background': '#df2a1e'}}>
                                    <img src="/img/Academic Programs.png" alt="" /></div>
                        </div>


                        <div className="down1 w-150" style={{'marginLeft': '-27px'}}  onClick={() => viewModel.scrollToDiv('Extra Curricular Activities')}>
                            <div className="dwn-img" style={{'background': '#f6b336'}}><img 
                            src="/img/Extra Curricular Activities.png"
                             alt="" /></div>
                            <div className="vertical"></div>
                            <div className="round-point "></div>
                            <div className="text-below">
                                Extra-Curricular <br />
                                Activities
                            </div>

                        </div>
                        <div className="up up1 w-150" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Faculty information')}>
                            <div className="text-above">Faculty <br />
                                Information</div>
                            <div className="round-point"></div>
                            <div className="vertical"></div>
                            <div className="dwn-img mt-3" style={{'background': '#7da4a1'}}><img 
                            
                            src="/img/Faculty Information.png"
                            
                            
                            
                            alt="" /></div>
                        </div>








                        <div className="down2 w-150" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Facilities & Infrastructure')}>
                            <div className="dwn-img" style={{'background': '#54cfcd'}}><img
                            
                            src="/img/facilities_infrastruture.png" alt="" /></div>
                            <div className="vertical"></div>
                            <div className="round-point "></div>
                            <div className="text-below">
                            Facility & <br />
                                Infrastructure
                            </div>

                        </div>
                       

                        <div className="up up2 w-150" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Achievements & Recognitions')}>
                            <div className="text-above">Achievements & <br />
                                Recognitions</div>
                            <div className="round-point"></div>
                            <div className="vertical"></div>
                            <div className="dwn-img mt-3" style={{'background': '#2282b4'}}><img 
                            src="/img/Achievments_Recognition.png" 
                            
                            
                            alt="" /></div>
                        </div>



                        <div className="down3 w-150" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Parent Testomonials')}>
                            <div className="dwn-img" style={{'background': '#21586d'}}><img 
                            src="/img/Parent Testimonials.png"
                            
                            alt="" /></div>
                            <div className="vertical"></div>
                            <div className="round-point "></div>
                            <div className="text-below">
                            Parent <br />
                                Testimonials
                            </div>
                        </div>
                        <div className="up up3 w-150" style={{'marginLeft': '-27px'}} onClick={() => viewModel.scrollToDiv('Gallery')}>
                            <div className="text-above"> School <br />
                                Gallery</div>
                            <div className="round-point"></div>
                            <div className="vertical"></div>
                            <div className="dwn-img mt-3" style={{'background': '#373737'}}><img 
                            src="/img/School Gallery.png"
                            
                            alt="" /></div>
                        </div>
                    </div>

                    </section>


                    {/* <div className="col-md-12">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabIndex={0} >
                            <div className="row">
                                <div className="col-md-12" id="scrollspyHeading1">
                                    <div className="card p-4 h-100 details-card">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className="f-22 header-title-color">About Us</h4>
                                                <div className="address" dangerouslySetInnerHTML={{ __html: viewModel.school.about }}>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

{
                                                viewModel.lstContent.map((item:any, i:any) =>
                                                
                                                
                                                
                                                {

                                                    if (!viewModel.divRefs[item.title]) {
                                                        viewModel.divRefs[item.title] = React.createRef();
                                                      }


                                                      if (!viewModel.divRefs['Gallery']) {
                                                        viewModel.divRefs['Gallery'] = React.createRef();
                                                      }
                                                



                                                    
                                                    return (   <div className="col-md-12 mb-4"
                    key={item.title}
                    ref={viewModel.divRefs[item.title]}

                     
                    >
                        <div className="content-title">
                            {item.title}
                        </div>
                        <div className="content-description" dangerouslySetInnerHTML={{ __html: item.content }}>
                            
                        </div>
                    </div>)
                                                }
                                                    )}

{

viewModel.schoolgalleries.length>0?(
    <div className="col-md-12"   key='Gallery'
    ref={viewModel.divRefs['Gallery']}    >
        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabIndex={0}>
            <div className="row">
                <div className="col-md-12 mt-4" id="scrollspyHeading1">
                    <div className="card p-4 h-100 details-card">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="f-22 header-title-color">School Gallery</h4>

                                {/* <h2><b className="header-title-color">School Gallery</b></h2> */}
                            </div>
                        </div>


                        <div className="row">

                            {
                                viewModel.schoolgalleries.map((item, i) => (
                                    <div className="col-md-3 mb-4">
                                        <div className="tf__about_us_img">
                                            <img src={item} className="scl-gallery school-img w-100 hover-shadow cursor" alt="" onClick={() => viewModel.openLightbox(i)} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>




                        <div>

                            <Lightbox
                                isOpen={viewModel.lightboxOpen}
                                onClose={viewModel.closeLightbox}
                                imageURLs={viewModel.schoolgalleries}
                                currentIndex={viewModel.currentIndex}
                                onPrev={viewModel.goToPrev}
                                onNext={viewModel.goToNext}
                                caption={viewModel.caption}
                            />

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>):''
}


                </div>
            </div>
        </section>
        <Footer />
    </>);



}
export default SchoolDetails;