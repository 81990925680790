import { EduNewsViewModel } from "./EduNewsViewModel";
import "../../components/carousel.css"
const EduNewsView:React.FC=()=>
{

    const viewMode=EduNewsViewModel();
    return(  <section className="gallery py-5">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <h2 className="text-center mb-0 fw-bold">In the Headlines</h2>
                <div className="carousel-container pr" >
                                 
                    <div className="gallery-carousel owl-carousel owl-theme">
                    {
                          viewMode.rssFeeds.map((item, i) => (
                        <div key={i} onClick={(e)=> viewMode.openFeedPage(item.link)}><img src={item.enclosure["@attributes"].url} className="img-fluid carousel-img-auto"  alt={item.title}  loading="lazy"/>
                        <p className="text-center text-dark active-text m-0">{item.title}</p>
                        </div>
                          ))
  }
                       </div>
  
                    <div className="align-ver-center">
                        <a className="carousel-control-prev prev-btn ml-50" data-slide="prev"  >
                            <i className="fa fa-angle-left"></i>
                        </a>
                        <a className="carousel-control-next next-btn mr-30" data-slide="next">
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </div>
  
                </div>
                
            </div>
            <div  className="col-12 text-center"> <button className="eti-btn"  onClick={ viewMode.OpenEduNews}>View All</button></div>
        </div>
     
    </div>
  </section>);



}

export default  EduNewsView;