const HomeStoreHelp:React.FC=()=>
{
return (

    <section className="mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="fw-600 mt-3">School Stores</h2>
                        <p>Enhance the parent and student experience with a customized school essentials store on <br/>
                            our platform. Enjoy seamless shopping making access to necessary items convenient. <br/>
                            Partner with us in creating an efficient shopping environment for your school community.
                        </p><p>
                    </p></div>
                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/1.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Back to School Kits</p>
                    </div>

                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/2.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Uniforms</p>
                    </div>

                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/3.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Books</p>
                    </div>

                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/4.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Stationary</p>
                    </div>

                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/5.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Memorabilia</p>
                    </div>


                    <div className="col-sm-2 text-center">
                        <div className="img-box">
                            <a href="products.html" className="store-cetgories"><img src="img/images/6.png" className="img-fluid" alt=""/></a>
                        </div>
                        <p className="fw-600 text-dark">Toys</p>
                    </div>

                    <div className="col-md-12 text-center">
                        <button className="eti-btn">
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </section>
)


}
export default HomeStoreHelp;