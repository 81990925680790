import { useNavigate } from "react-router";

export function AdmissionHelpMenuViewModel()
{
    const navigate = useNavigate();
const listingClick =() => {


  
    navigate('/statelist');

}



    return{listingClick}
}