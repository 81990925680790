import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AppState, Action, ActionType } from './Page.Actions';

const initialState: AppState = {
  storeData: {
    storeData: null,
    viewName: '',
  },
};

const persistConfig = {
  key: 'root', // Change this key as needed
  storage,
};

const rootReducer = (state: AppState = initialState, action: Action): AppState => {
  switch (action.type) {
    case ActionType.STORE:
      return { ...state, storeData: action.payload };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
